/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/source-sans-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/source-sans-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

h1 {
  font-size: 2.4rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
  font-weight: 900;
}

main {
  h1 {
    padding-bottom: 1.5rem;
  }
  h2 {
    padding-bottom: 1.3rem;
  }
  h3 {
    margin-bottom: 2.2rem;
    border-bottom: 1px solid $color-hr;
  }
  p {
    padding-bottom: 1rem;
  }
}


body {
  font-family: 'Source Sans Pro', Verdana, Arial, Helvetica, sans-serif;
  color: $color-grey;
  line-height: 1.4;
}

hr {
  margin: 0 0 15px 0;
  height: 1px;
  border: 0;
  width: 100%;
  clear: both;
  background-color: $color-hr;
  color: $color-hr;

  @media (max-width: $breakpoint-medium) {
    width: 50%;
    min-width: 235px;
  }
}

a {
  text-decoration: none;
  color: $color-primary;

  &:hover {
    color: $color-lightgrey
  }
}