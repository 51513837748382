* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.color-giveba {
  color: $color-giveba;
}

.color-von_drateln {
  color: $color-von_drateln;
}

main {
  padding: 50px 0;
}

::-moz-selection { /* Code for Firefox */
  color: $color-white;
  background: $color-primary;
}

::selection {
  color: $color-white;
  background: $color-primary;
}



/* ---- images ---- */
img {
  width: 100%;
  object-fit: cover;
}
