@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}

/*--- Only Chrome 28+, Opera ≥ 14 ---*/
@mixin only_chrome() {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: .001dpcm) {
    @content;
  }
}

/*--- Only iOS Safari ≥ 9 ---*/
@mixin only_ios() {
  @supports (-webkit-text-size-adjust:none) and (not (-ms-accelerator:true)) and (not (-moz-appearance:none)) {
    @content;
  }
}

@mixin only_safari() {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @content;
  }
}

/*--- Only Edge ---*/
@mixin only_edge($selector, $map) {
  @supports (-ms-accelerator:true) {
    @content;
  }
}

/*--- Only IE ≤ 11 ---*/
@mixin only_ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
