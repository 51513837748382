.home-navigation {
  height: 70vh;

  li a {
    color: $color-white;
  }

  h2, p {
    text-shadow: 0 1px 5px #000000;
  }

  a:hover {
    h2, p {
      text-shadow: 0 0 0 #000000;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.3rem;
  }

  .home-navigation-list {
    display: flex;
    justify-content: space-between;
    list-style: none;

    &__item {
      margin-right: 7px;

      &:last-child {
        margin-right: 0;
      }

      .home-navigation-list__item-head {
        padding: 25vh 10px 10px 10px;
        text-align: end;
      }

      img {
        margin-top: 10px;
        height: 150px;
        box-shadow: 0 0 20px rgba(0, 0, 0, .3);
      }

      &:hover {
        .home-navigation-list__item-head {
          background-color: $color-grey;

          transition: background-color 0.15s linear;
        }

        img {
          transition: box-shadow 0.15s linear;

          box-shadow: 0 0 0 2px $color-primary;
        }
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  @media (max-width: $breakpoint-medium) {

    height: 100vh;

    .home-navigation-list {
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: space-evenly;

      &__item {
        margin-right: 0;

        .home-navigation-list__item-head {
          width: 50%;
          padding: 10px;
          text-align: start;
        }
      }

      li a {
        display: flex;
        flex-direction: row-reverse;
        max-height: 200px;

        img {
          width: auto;
          height: 22vh;
          max-height: 190px;
          flex-shrink: 0;
          object-fit: cover;

          margin-top: 0;
          margin-right: 10px;

          @media (max-width: $breakpoint-small) {
            max-width: 150px;
          }
        }
      }
    }
  }
}