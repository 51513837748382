.footer-container {
  padding: clamp(25px, 5%, 50px) 0;

  @include only_ie {
    padding: 50px 0;
  }

  display: flex;

  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
  }


  .footer__kontakt-box {
    margin-right: 10%;
  }

  .footer__change-box {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    img {
      width: 100%;
      }


    .footer__change-box-trenner {
      width: 1px;
      height: auto;
      max-height: 150px;
      margin: 15px 30px 0;
      display: block;
      background: $color-lightgrey;
      flex-shrink: 0;
    }

    @media (max-width: $breakpoint-medium) {
      margin-top: clamp(25px, 5%, 50px);
    }
  }
}


