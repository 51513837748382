.subpage-navigation {
  height: 55vh;

  li a {
    color: $color-white;
  }

  h2, p {
    text-shadow: 0 1px 5px #000000;
  }

  a:hover {
    h2, p {
      text-shadow: 0 0 0 #000000;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.3rem;
  }

  .subpage-navigation-list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    list-style: none;
    max-width: 220px;
    height: 100%;

    @include only_ie {
      justify-content: space-around;
    }

    &__item {
      background-color: $color-lightgrey-trans;

      &.active {
        background-color: $color-primary;
        a {
          h2, p {
            text-shadow: 0 0 0 #000000;
          }
        }

      }

      .subpage-navigation-list__item-head {
        padding: 10px;
      }

      &:hover {
        .subpage-navigation-list__item-head {
          background-color: $color-grey;

          transition: background-color 0.15s linear;
        }

      }

    }
  }

  //@media (max-width: $breakpoint-medium) {
  //
  //  height: 100vh;
  //
  //  .subpage-navigation-list {
  //    display: flex;
  //    flex-direction: column;
  //    height: 100vh;
  //    justify-content: space-evenly;
  //
  //    &__item {
  //      margin-right: 0;
  //
  //      .subpage-navigation-list__item-head {
  //        width: 50%;
  //        padding: 10px;
  //        text-align: start;
  //      }
  //    }
  //
  //    li a {
  //      display: flex;
  //      flex-direction: row-reverse;
  //      max-height: 200px;
  //
  //      img {
  //        width: auto;
  //        height: 22vh;
  //        max-height: 190px;
  //        flex-shrink: 0;
  //        object-fit: cover;
  //
  //        margin-top: 0;
  //        margin-right: 10px;
  //
  //        @media (max-width: $breakpoint-small) {
  //          max-width: 150px;
  //        }
  //      }
  //    }
  //  }
  //}
}