.main-wrap {
  width: 80%;
  max-width: 1024px;
  margin: 0 auto;
  height: 100%;

  @media only screen and (max-width: $breakpoint-large) {
    width: 85%;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    width: 90%;
  }

  @media only screen and (max-width: $breakpoint-small) {
    width: 92%;
  }


}

/* --- Zweispalter --- */

.zweispalter {
  display: flex;
}

.col-75 {
  width: 75%;
}

.col-50 {
  width: 50%;
}

.col-25 {
  width: 25%;
}


.col-25,
.col-50,
.col-75 {
  &:first-child {
    margin-right: 12px;
  }
  &:last-child {
    margin-left: 12px;
  }
}

@media only screen and (max-width: 600px) {

  .zweispalter {
    flex-direction: column;
  }

  .zweispalter.mobile-reverse {
    flex-direction: column-reverse;
  }

  .col-25,
  .col-50,
  .col-75 {

    width: 100%;


    &:first-child {
      margin-right: 0;
    }
    &:last-child {
      margin-left: 0;
    }
  }
}

/* --- section --- */

section {
  padding-bottom: 5rem;
}

