/* scss/GloaleElemente/_osmconfirm.scss */
.confirm-container {
  background:url('../img/referenzen/referenzen-map-blur-03.jpg') no-repeat;
  background-size: cover;
}

.confirm-container__content {
  max-width: 500px;
  min-height: 320px;
  padding: 30px;
}

.osm-map {
  display: none;
}

