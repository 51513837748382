.home-navigation {
  background: url("../img/startseite/Luftbild.jpg") center top;
  background-size: cover;
}

//Navi-Bilder der Unterseiten

.projekte {
  .subpage-navigation {
    background: url("../img/projekte/Fildenstrasse_Aussen_Nacht.jpg") center top;
    background-size: cover;
  }
}

.ueber-uns {
  .subpage-navigation {
    background: url("../img/ueber-uns/Fildenstrasse_Schlafen_gesp.jpg") center top;
    background-size: cover;
  }
}

.referenzen {
  .subpage-navigation {
    background: url("../img/referenzen/Fildenstrasse_Aussen_Tag_gesp.jpg") center top;
    background-size: cover;
  }
}

.kontakt {
  .subpage-navigation {
    background: url("../img/kontakt/Fildenstrasse_Bad.jpg") center top;
    background-size: cover;
  }
}

.impressum,
.datenschutz{
  .subpage-navigation {
    background: url("../img/kontakt/Fildenstrasse_Bad.jpg") center top;
    background-size: cover;
  }
}

// colors
$color-giveba: #b91c68;
$color-von_drateln: #134C7A;

$color-primary: $color-giveba;

$color-white: #fff;
$color-hr: #b6b5b4;
$color-grey: #605d5c;
$color-lightgrey: #9c9c9c;
$color-lightgrey-trans: rgba(156,156,156,0.6);

//breakpoints
$breakpoint-large: 1180px;
$breakpoint-medium: 930px;
$breakpoint-small: 560px;