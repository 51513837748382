.button {
  margin: 20px 40px 0 0;
  cursor: pointer;
  background-color: $color-primary;
  color: $color-white;
  padding: 10px;
  max-width: 220px;
  text-align: center;

  &:hover {
    background-color: #605d5c;
    transition: background-color 0.15s linear;
  }
}