.referenzen {
  .referenztabelle {

    width: 100%;
    max-width: 500px;

td, th {
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: $color-hr;
}

    td.align-center {
      text-align: center;
    }
  }
}